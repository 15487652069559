// THIS FILE IS GENERATED AUTOMATICALLY DURING BUILD
// SHOULD NOT BE EDITED MANUALLY
import { version } from '../../../../../package.json';
export const versionInfo = {
  buildTime:'2024-10-01T11:42:39+02:00',
  gitHash:'043ea5d66',
  branchName:'gpd',
  tag:'v1.3.66_gpd',
  version:version
};
